.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"),
    url("./../public/assets/fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url("./../public/assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Light";
  src: local("Inter-Light"),
    url("./../public/assets/fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNowDisplay-Bold";
  src: local("HelveticaNowDisplay-Bold"),
    url("./../public/assets/fonts/HelveticaNowDisplay-Bold.ttf")
      format("truetype");
}

@font-face {
  font-family: "HelveticaNowDisplay-Light";
  src: local("HelveticaNowDisplay-Light"),
    url("./../public/assets/fonts/HelveticaNowDisplay-Light.ttf")
      format("truetype");
}

@font-face {
  font-family: "HelveticaNowDisplay-Regular";
  src: local("HelveticaNowDisplay-Regular"),
    url("./../public/assets/fonts/HelveticaNowDisplay-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "InstrumentSerif-Italic";
  src: local("InstrumentSerif-Italic"),
    url("./../public/assets/fonts/InstrumentSerif-Italic.ttf")
      format("truetype");
}

@font-face {
  font-family: "Consolas";
  src: local("Consolas"),
    url("./../public/assets/fonts/Consolas.TTF") format("truetype");
}
