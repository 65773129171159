body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-moz-selection {
  background: #ef233c;
  color: #ffffff;
}

::-webkit-selection {
  background: #ef233c;
  color: #ffffff;
}

::selection {
  background: #ef233c;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-track:hover {
  background-color: #e0e0e0;
}

#home-page-left-grid-content {
  height: 250px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

#home-page-left-grid-content-text {
  font-family: HelveticaNowDisplay-Light;
  font-size: 34px;
  text-transform: uppercase;
}

@media (max-width: 1800px) {
  #home-page-left-grid-content-text {
    font-size: 30px;
  }
}

#home-page-left-grid-content-since-date {
  font-family: HelveticaNowDisplay-Light;
  font-size: 18px;
}

#home-page-center-main-grid {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  position: relative;
  border-left: 1px solid rgba(229, 229, 229, 0.6);
  border-right: 1px solid rgba(229, 229, 229, 0.6);
}

#main-grid-div {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#main-grid-text {
  font-family: HelveticaNowDisplay-Regular;
  font-size: 45px;
  margin: 0;
  color: #080808;
  z-index: 1;
}

@media (max-width: 1800px) {
  #main-grid-text {
    font-size: 38px;
  }
}

#main-grid-text-span {
  font-family: InstrumentSerif-Italic;
  font-weight: 600;
}

#main-grid-subtext {
  font-family: HelveticaNowDisplay-Light;
  font-size: 23px;
  letter-spacing: 0.1px;
  margin: 0;
  color: #080808;
}

@media (max-width: 1800px) {
  #main-grid-subtext {
    font-size: 21px;
  }
}

#main-grid-subtext-span {
  background-color: #ef233c;
  padding: 3px;
  color: #fff;
  font-size: 24px;
}

#home-page-right-grid {
  height: 180px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

#home-page-right-logo-grid {
  text-align: right;
  padding: 10px 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#home-page-right-grid-text {
  font-family: HelveticaNowDisplay-Regular;
  font-size: 22px;
}

@media (max-width: 1800px) {
  #home-page-right-grid-text {
    font-size: 20px;
  }
}

#home-page-right-grid-img {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}

.animate-text {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.animate-text-delayed {
  opacity: 0;
  animation: fadeIn 1s forwards 1s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

#services-main-grid {
  background-size: cover;
  display: flex;
  align-items: center;
}

#services-inner-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  transform-origin: top left;
}

#services-title-text {
  font-family: HelveticaNowDisplay-Light;
  font-size: 40px;
  color: #fff;
}

#services-title-text-first-letter {
  background-color: #ef233c;
  padding: 2px;
  margin: 1px;
}

#services-inner-container::-webkit-scrollbar {
  display: none;
}

#services-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

#featured-work-title-text {
  font-family: HelveticaNowDisplay-Light;
  font-size: 40px;
  color: #fff;
}

#featured-work-title-first-letter {
  background-color: #ef233c;
  padding: 2px;
  margin: 1px;
}

#featured-work-title-first-letter-mobile {
  background-color: #ef233c;
  padding: 0 5px;
}

#services-bento-grid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 200px);
  gap: 10px;
  margin-top: 50px !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fuzzy-circle {
  width: 170px;
  height: 170px;
  background: radial-gradient(
    circle,
    rgba(102, 0, 255, 1) 15%,
    rgba(102, 0, 255, 0) 70%
  );
  filter: blur(40px);
  border-radius: 50%;
  position: relative;
}

.box {
  border-radius: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid #333;
  position: relative;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
  transition: transform 0.3s ease-in-out;
}

.box img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

#box1 {
  justify-content: flex-start;
  grid-column: span 2;
  grid-row: span 2;
  transition: transform 0.8s;
}

#box1-header-text {
  font-family: HelveticaNowDisplay-Regular;
  font-size: 1.5em;
  color: rgba(255, 252, 252, 0.8);
  padding: 10px;
  letter-spacing: 0.5px;
}

.box1-content-text {
  font-family: HelveticaNowDisplay-Light !important;
  color: rgba(255, 255, 255, 0.5) !important;
  padding: 0 20px !important;
  font-size: 1em !important;
}

#box2 {
  grid-column: span 1;
  background-color: #9cb1ff;
  justify-content: center;
}

#box3 {
  grid-column: span 1;
  background-color: #395ee3;
  justify-content: center;
}

#box4 {
  justify-content: flex-start;
  grid-column: span 2;
  grid-row: span 2;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

#box5 {
  grid-column: span 1;
  justify-content: center;
}

#box6 {
  border-radius: 10px;
  grid-column: span 1;
  justify-content: center;
  overflow: hidden;
  z-index: 0;
}

#box6::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: radial-gradient(
    circle,
    rgba(102, 0, 255, 1) 15%,
    rgba(102, 0, 255, 0) 70%
  );
  filter: blur(45px);
  z-index: -1;
  border-radius: 50%;
}

#box7 {
  grid-column: span 1;
  grid-row: span 10;
  justify-content: center;
  z-index: 0;
  height: auto;
  min-height: 200px;
  max-height: 80vh;
}

#box8 {
  justify-content: flex-start;
  grid-column: span 2;
  grid-row: span 10;
  transition: transform 0.8s;
  justify-content: flex-start;
}

.integration-circle {
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.integration-circle::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 0;
}

.icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: circle-animation 14s linear infinite;
}

.icon:nth-child(1) {
  transform: rotate(0deg) translateX(100px);
  animation-delay: 0s;
}

.icon:nth-child(2) {
  transform: rotate(51.43deg) translateX(100px);
  animation-delay: 2s;
}

.icon:nth-child(3) {
  transform: rotate(102.86deg) translateX(100px);
  animation-delay: 4s;
}

.icon:nth-child(4) {
  transform: rotate(154.29deg) translateX(100px);
  animation-delay: 6s;
}

.icon:nth-child(5) {
  transform: rotate(205.71deg) translateX(100px);
  animation-delay: 8s;
}

.icon:nth-child(6) {
  transform: rotate(257.14deg) translateX(100px);
  animation-delay: 10s;
}

.icon:nth-child(7) {
  transform: rotate(308.57deg) translateX(100px);
  animation-delay: 12s;
}

@keyframes circle-animation {
  0% {
    transform: rotate(0deg) translateX(100px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(100px) rotate(-360deg);
  }
}

.circle-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 10px 20px 0 20px;
  flex-wrap: wrap;
}

#box6mobile {
  border: 2px solid #333;
  height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

#box6mobile::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: radial-gradient(
    circle,
    rgba(102, 0, 255, 1) 15%,
    rgba(102, 0, 255, 0) 70%
  );
  filter: blur(45px);
  z-index: -1;
  border-radius: 50%;
}

@keyframes shake {
  0% {
    transform: translateX(0) rotate(-10deg);
  }
  25% {
    transform: translateX(-15px) rotate(-10deg);
  }
  50% {
    transform: translateX(15px) rotate(-10deg);
  }
  75% {
    transform: translateX(-15px) rotate(-10deg);
  }
  100% {
    transform: translateX(0) rotate(-10deg);
  }
}

@keyframes rotateBorder1 {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(370deg);
  }
}

@keyframes rotateBorder2 {
  0% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(-370deg);
  }
}

.responsive-div {
  position: relative;
  width: 50%;
  height: 50%;
}

@media (max-width: 1024px) {
  .responsive-div {
    width: 80%;
    height: 50%;
  }
}

@media (max-width: 900px) {
  .responsive-div {
    width: 90%;
    height: 55%;
  }
}

.why-choose-us-grid {
  background-color: #171718;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  margin: 0 0 !important;
}

.rotating-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgba(171, 171, 171, 0.4);
  pointer-events: none;
}

.rotating-rectangle.one {
  transform: rotate(10deg);
  animation: rotateBorder1 13s infinite linear;
}

.rotating-rectangle.two {
  transform: rotate(-10deg);
  animation: rotateBorder2 13s infinite linear;
}

#footer-main-grid {
  max-height: calc(100vh - 60px);
  height: auto;
  background-position: top;
  background-size: cover;
}

#footer-content-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

#footer-header-company {
  display: flex;
  margin-top: 30px;
  justify-content: flex-start;
}

#company-logo-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

#company-name {
  font-family: HelveticaNowDisplay-Regular;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
}

#footer-items-grid {
  margin-top: 10px;
  justify-content: flex-start;
}

.footer-item-header {
  font-family: HelveticaNowDisplay-Regular !important;
  color: rgba(122, 119, 119, 1) !important;
  font-size: 16px !important;
}

#footer-divider-line {
  align-items: center;
  margin-top: 30px;
  border-image: linear-gradient(to right, #9cb1ff 0%, #008cff 100%) 1;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

#footer-all-rights-grid {
  align-items: center;
  margin: 15px 0;
  justify-content: center;
  display: flex;
}

#footer-all-rights-text {
  font-family: Inter-Light;
  color: rgba(255, 254, 254, 0.8);
  font-size: 15px;
}

#why-choose-us-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

#why-choose-us-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 10px 20px;
  background-image: linear-gradient(
    to bottom,
    rgb(30, 30, 31) 1px,
    transparent 1px
  );
  background-size: 5px 5px;
}

#why-choose-us-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50%;
  height: 100%;
  font-family: HelveticaNowDisplay-Light;
  font-size: 1.7vw;
  color: #fff;
  text-align: center;
}

#why-choose-us-header-sup {
  font-size: 18px;
  color: #ff7e5f;
  margin-bottom: 15px;
  margin-left: 3px;
  align-items: center;
  display: flex;
}

#why-choose-us-text {
  font-family: HelveticaNowDisplay-Light;
  font-size: 1.2vw;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.2;
  flex: 0 0 50%;
}

#featured-work-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#featured-work-header-sup {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
  margin-left: 5px;
  align-items: center;
  display: flex;
  letter-spacing: 0.15em;
  font-family: HelveticaNowDisplay-Light;
}

.featured-work-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

#projects {
  margin-top: 10px;
  padding-bottom: 30px;
}

.project-div {
  display: flex !important;
  align-items: center;
  cursor: pointer !important;
  position: relative !important;
  min-height: 110px !important;
  overflow: hidden;
  transform: scale(1);
}

.project-div.active {
  transform: scale(1.005);
}

#project-name-grid {
  display: flex;
  align-items: center;
}

.project-numbering {
  font-family: HelveticaNowDisplay-Light !important;
  font-size: 1em !important;
  margin-top: 10px !important;
  line-height: 1.1 !important;
  padding: 5px 10px !important;
  text-align: left !important;
  color: rgba(255, 255, 255, 0.4) !important;
}

.project-numbering.active {
  color: rgba(255, 255, 255, 0.9) !important;
}

.project-name {
  font-family: HelveticaNowDisplay-Light !important;
  /* font-size: 1.2em !important; */
  color: rgba(255, 255, 255, 0.4) !important;
  margin: 10px 0 0 10px !important;
  line-height: 1 !important;
  text-align: left !important;
  text-transform: uppercase !important;
}

.project-name.active {
  color: rgba(255, 255, 255, 0.9) !important;
}

#project-year-grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-year {
  font-family: HelveticaNowDisplay-Light !important;
  font-size: 1em !important;
  color: rgba(255, 255, 255, 0.4) !important;
  margin-top: 10px !important;
  line-height: 1.1 !important;
  padding: 5px 10px !important;
  text-align: center !important;
}

.project-year.active {
  color: rgba(44, 255, 5, 0.9) !important;
}

#project-title-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
}

.project-title {
  font-family: HelveticaNowDisplay-Light !important;
  color: rgba(255, 255, 255, 0.4) !important;
  padding: 5px !important;
  line-height: 1 !important;
  text-transform: uppercase !important;
  position: relative !important;
}

.project-title.active {
  color: rgba(255, 255, 255, 0.9) !important;
}

.project-title-underline {
  content: "''" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  height: 2px !important;
  width: 0% !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  transition: width 0.8s ease !important;
}

.project-title-underline.active {
  width: 100% !important;
}

#project-description-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-description {
  font-family: HelveticaNowDisplay-Light !important;
  color: rgba(255, 255, 255, 0.9) !important;
  padding: 5px !important;
  line-height: 1 !important;
  text-align: left !important;
  letter-spacing: 1px;
}

#featured-work-title-text-mobile {
  font-family: HelveticaNowDisplay-Light;
  font-size: 25px;
  color: #fff;
}

.project-numbering-mobile {
  font-family: HelveticaNowDisplay-Light !important;
  font-size: 1em !important;
  line-height: 1.1 !important;
  padding: 5px !important;
  text-align: left !important;
  color: rgba(255, 255, 255, 0.4) !important;
}

.project-name-mobile {
  font-family: HelveticaNowDisplay-Light !important;
  font-size: 0.8em !important;
  color: rgba(255, 255, 255, 0.6) !important;
  padding: 5px !important;
  line-height: 1 !important;
  text-align: left !important;
  text-transform: uppercase !important;
}

.project-title-mobile {
  font-family: HelveticaNowDisplay-Light !important;
  font-size: 1em !important;
  color: rgba(255, 255, 255, 0.9) !important;
  padding: 0 5px !important;
  line-height: 1 !important;
  text-align: left !important;
  text-transform: uppercase !important;
  margin-top: 10px !important;
}

.project-year-mobile {
  font-family: HelveticaNowDisplay-Light !important;
  font-size: 1em !important;
  color: rgba(44, 255, 5, 0.6) !important;
  margin-top: 10px !important;
  line-height: 1.1 !important;
  padding: 5px 10px !important;
  text-align: center !important;
}

.project-description-mobile {
  font-family: HelveticaNowDisplay-Light !important;
  font-size: 0.9em !important;
  color: rgba(255, 255, 255, 0.9) !important;
  padding: 5px !important;
  line-height: 1.1 !important;
  text-align: left !important;
}

.loader {
  width: 60px;
  aspect-ratio: 1;
  display: grid;
  grid: 50%/50%;
  color: #ef233c;
  border-radius: 50%;
  --_g: no-repeat linear-gradient(currentColor 0 0);
  background: var(--_g), var(--_g), var(--_g);
  background-size: 50.1% 50.1%;
  animation: l9-0 1.5s infinite steps(1) alternate,
    l9-0-0 3s infinite steps(1) alternate;
}
.loader::before {
  content: "";
  background: currentColor;
  border-top-left-radius: 100px;
  transform: perspective(150px) rotateY(0deg) rotateX(0deg);
  transform-origin: bottom right;
  animation: l9-1 1.5s infinite linear alternate;
}
@keyframes l9-0 {
  0% {
    background-position: 0 100%, 100% 100%, 100% 0;
  }
  33% {
    background-position: 100% 100%, 100% 100%, 100% 0;
  }
  66% {
    background-position: 100% 0, 100% 0, 100% 0;
  }
}
@keyframes l9-0-0 {
  0% {
    transform: scaleX(1) rotate(0deg);
  }
  50% {
    transform: scaleX(-1) rotate(-90deg);
  }
}
@keyframes l9-1 {
  16.5% {
    transform: perspective(150px) rotateX(-90deg) rotateY(0deg) rotateX(0deg);
    filter: grayscale(0.8);
  }
  33% {
    transform: perspective(150px) rotateX(-180deg) rotateY(0deg) rotateX(0deg);
  }
  66% {
    transform: perspective(150px) rotateX(-180deg) rotateY(-180deg)
      rotateX(0deg);
  }
  100% {
    transform: perspective(150px) rotateX(-180deg) rotateY(-180deg)
      rotateX(-180deg);
    filter: grayscale(0.8);
  }
}
